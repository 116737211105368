import React, { useState } from "react"
import { motion } from "framer-motion"
import { useForm } from "react-hook-form"
import { FaPhone, FaClock } from "react-icons/fa"
import styles from "./contact.module.scss"
import API from "../../assets/api"

const sectionVariants = {
  visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
  hidden: { opacity: 0, y: 50 },
}
const staggerContainer = {
  hidden: { opacity: 1 },
  visible: {
    transition: {
      staggerChildren: 0.2,
    },
  },
}

const formFieldVariants = {
  hidden: { opacity: 0, y: 30 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.6 } },
}

const buttonVariants = {
  hover: {
    scale: 1.05,
    transition: {
      duration: 0.3,
      yoyo: Infinity,
    },
  },
}

const ContactPage: React.FC = () => {
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)

  const { register, handleSubmit, reset } = useForm({
    defaultValues: {
      name: "",
      email: "",
      phone: "",
      message: "",
    },
  })

  const onSubmit = (data: {
    name: string
    email: string
    phone: string
    message: string
  }) => {
    const formData = { ...data, category: "contact request" }
    API.sendContactRequest(formData).then(() => {
      setIsSubmitted(true)
      setShowSuccessMessage(true)
      reset()
    })
  }

  return (
    <>
      <div className={styles.parallaxContainer}></div>

      <motion.div
        className={styles.contactPageContainer}
        initial="hidden"
        animate="visible"
        variants={staggerContainer}
      >
        <motion.div className={styles.leftSection} variants={sectionVariants}>
          <h2>Contact Information</h2>
          <div className={styles.contactDetails}>
            <h3>Main Office</h3>

            <motion.div className={styles.iconRow} variants={formFieldVariants}>
              <div className={styles.iconCircle}>
                <FaClock size={24} />
              </div>
              <p className={styles.text}>
                1430 Bradley Ln., suite 150 A, Carrollton, Texas 75007
              </p>
            </motion.div>

            <motion.div className={styles.iconRow} variants={formFieldVariants}>
              <div className={styles.iconCircle}>
                <FaPhone size={24} />
              </div>
              <p className={styles.text}>+1 877 494 7174</p>
            </motion.div>

            <motion.div className={styles.iconRow} variants={formFieldVariants}>
              <div className={styles.iconCircle}>
                <FaClock size={24} />
              </div>
              <p className={styles.text}>Mon - Fri: 6:00 AM - 5:00 PM</p>
            </motion.div>

            <motion.div className={styles.iconRow} variants={formFieldVariants}>
              <div className={styles.iconCircle}>
                <FaClock size={24} />
              </div>
              <p className={styles.text}>dispatch@ctifleet.com</p>
            </motion.div>
          </div>
        </motion.div>

        <motion.div
          className={styles.rightSection}
          initial="hidden"
          animate="visible"
          variants={staggerContainer}
        >
          <form
            className={styles.contactForm}
            onSubmit={handleSubmit(onSubmit)}
          >
            <h3>Contact Us</h3>

            <motion.div
              className={styles.formGroup}
              variants={formFieldVariants}
            >
              <label htmlFor="name">Name or Company Name *</label>
              <input
                type="text"
                id="name"
                placeholder="John Doe or ABC Logistics"
                {...register("name", { required: true })}
              />
            </motion.div>

            <motion.div
              className={styles.formGroup}
              variants={formFieldVariants}
            >
              <label htmlFor="email">Email *</label>
              <input
                type="email"
                id="email"
                placeholder="you@example.com"
                {...register("email", { required: true })}
              />
            </motion.div>

            <motion.div
              className={styles.formGroup}
              variants={formFieldVariants}
            >
              <label htmlFor="phone">Phone Number *</label>
              <input
                type="tel"
                id="phone"
                placeholder="+1 (000) 444-4444"
                {...register("phone", { required: true })}
              />
            </motion.div>

            <motion.div
              className={styles.formGroup}
              variants={formFieldVariants}
            >
              <label htmlFor="message">Message or Question *</label>
              <textarea
                id="message"
                placeholder="Your message or question here..."
                {...register("message", { required: true })}
              />
            </motion.div>

            <motion.button
              type="submit"
              className={styles.submitButton}
              variants={buttonVariants}
              whileHover="hover"
            >
              Submit
            </motion.button>

            {isSubmitted && showSuccessMessage && (
              <motion.div
                className={styles.successMessage}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
              >
                <p>
                  Thank you for reaching out! We will get back to you shortly.
                </p>
              </motion.div>
            )}
          </form>
        </motion.div>
      </motion.div>

      <motion.section
        className={styles.mapContainer}
        initial="hidden"
        whileInView="visible"
        variants={sectionVariants}
      >
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3346.45604654417!2d-96.92622332427857!3d32.991750173574545!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x864c2f59029864b5%3A0x2746a5f4f708ca77!2sCapitol%20Trucking%20inc!5e0!3m2!1spl!2spl!4v1726055756649!5m2!1spl!2spl"
          className={styles.mapIframe}
          allowFullScreen={true}
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </motion.section>
    </>
  )
}

export default ContactPage
